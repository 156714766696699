<template>
  <div class="contentProduct">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title loaderPosition">
          <div class="widthTitle">Gestion des produits</div>
          <div v-if="getProduitIsLoding" class=" loading">
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
          <div class="error-message ml-3 ">
            <div v-if="getProduitError" class="error">
              <ul v-if="Array.isArray(getProduitError)" class="mb-0">
                <li v-for="(e, index) in getProduitError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getProduitError }}</div>
            </div>
          </div>
        </div>
        <div>
          <b-button
            variant="success"
            class="config-btn ml-2"
            v-b-modal.addProduit
            ><font-awesome-icon icon="plus" /> Ajouter un produit</b-button
          >
          <b-modal
            id="addProduit"
            ref="addProduit"
            title="Nouvelle configuration"
            no-close-on-backdrop
            :hide-footer="true"
            :hide-header="true"
            @hidden="resetModal"
            modal-class="cutsom-modal-bootstrap custom-modal-size"
          >
            <div class="hader mb-2">
              <div class="titleSetting">Nouvelle produit</div>
              <div class="iconClose" @click.prevent="hideModal('addProduit')">
                <font-awesome-icon icon="times" />
              </div>
            </div>
            <Card>
              <template v-slot:body>
                <form
                  @submit.prevent="handleSubmitAddProduit"
                  class="form-modal-custom-style"
                >
                  <div class="ModelCol">
                    <b-form-group
                      label="Nom de produit"
                      label-for="Nom de produit"
                      class="input-modal-champ col-6 p-0"
                    >
                      <b-form-input
                        id="nom"
                        v-model="produit.nom"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Référence"
                      label-for="Référence"
                      class="input-modal-champ col-6 p-0"
                    >
                      <b-form-input
                        id="reference"
                        v-model="produit.reference"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="PU HT"
                      label-for="PU HT"
                      class="input-modal-champ col-3 p-0"
                    >
                      <b-form-input
                        id="pu_ht"
                        v-model="produit.pu_ht"
                        required
                        @input="changePuHT"
                        pattern="[0-9-,-.]+"
                        title="This input should be only numbers"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      v-if="!isSelectInput"
                      label="TVA"
                      label-for="TVA"
                      class="input-modal-champ col-3 p-0"
                    >
                      <b-form-select
                        id="tva"
                        v-model="produit.tva"
                        required
                        :options="listTva"
                        @input="changePuHT(true)"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="TVA"
                      label-for="TVA"
                      class="input-modal-champ col-3 p-0"
                      v-if="isSelectInput"
                    >
                      <b-form-input
                        id="tva"
                        v-model="produit.tva"
                        required
                        @input="changePuHT()"
                        pattern="[0-9-,-.]+"
                        title="This input should be only numbers"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="PU TTC"
                      label-for="PU TTC"
                      class="input-modal-champ col-3 p-0"
                    >
                      <b-form-input
                        id="ttc"
                        v-model="produit.ttc"
                        @input="changePuTTC"
                        pattern="[0-9-,-.]+"
                        title="This input should be only numbers"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Devise"
                      label-for="Devise"
                      class="input-modal-champ col-3 p-0"
                    >
                      <b-form-select
                        id="devise"
                        v-model="produit.devise"
                        :options="listDevise"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Description"
                      label-for="Description"
                      class="input-modal-champ  "
                    >
                      <b-form-textarea
                        id="description"
                        row="3"
                        v-model="produit.description"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Longueur"
                      label-for="Longueur"
                      class="input-modal-champ col-2 p-0 "
                    >
                      <b-form-input
                        id="longueur"
                        v-model="produit.longueur"
                        pattern="[0-9-,-.]+"
                        title="This input should be only numbers"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Largeur"
                      label-for="Largeur"
                      class="input-modal-champ col-2 p-0 "
                    >
                      <b-form-input
                        id="largeur"
                        v-model="produit.largeur"
                        pattern="[0-9-,-.]+"
                        title="This input should be only numbers"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Hauteur"
                      label-for="Hauteur"
                      class="input-modal-champ col-2 p-0 "
                    >
                      <b-form-input
                        id="hauteur"
                        v-model="produit.hauteur"
                        pattern="[0-9-,-.]+"
                        title="This input should be only numbers"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Unité"
                      label-for="Unite_l"
                      class="input-modal-champ col-2 p-0 "
                    >
                      <b-form-select
                        id="unite_l"
                        :options="unites_longeur"
                        v-model="produit.unite_l"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Poids"
                      label-for="Poids"
                      class="input-modal-champ col-2 p-0 "
                    >
                      <b-form-input
                        id="poids"
                        v-model="produit.poids"
                        pattern="[0-9-,-.]+"
                        title="This input should be only numbers"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Unité"
                      label-for="Unite_p"
                      class="input-modal-champ col-2 mr-4 "
                    >
                      <b-form-select
                        id="unite_p"
                        :options="unites_poids"
                        v-model="produit.unite_p"
                        required
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Catégorie"
                      label-for="Catégorie"
                      class="input-modal-champ"
                    >
                      <b-form-select
                        id="categorie_id"
                        v-model="produit.categorie_id"
                        :options="getCategories"
                        text-field="nom_categorie"
                        value-field="id"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="actionModel">
                    <div class="messageError">
                      <div v-if="error" class="error">
                        <ul
                          v-if="Array.isArray(error)"
                          class="mb-0"
                          style="list-style-type: none;"
                        >
                          <li v-for="(e, index) in error" :key="index">
                            {{ e }}
                          </li>
                        </ul>
                        <div v-else>{{ error }}</div>
                      </div>
                    </div>
                    <b-button type="submit" class="button-valide-style ">
                      <span>
                        Valider
                        <div v-if="loading" class="loading ml-2">
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </span>
                    </b-button>
                  </div>
                </form>
              </template>
            </Card>
          </b-modal>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table-simple class="table-fiche custom-table-style mb-0" responsive>
          <b-tbody>
            <b-tr class="title-ligne tableFixHead">
              <b-th class="newcolor">Nom</b-th>
              <b-th class="newcolor">Réference </b-th>
              <b-th class="newcolor">PU HT</b-th>
              <b-th class="newcolor">TVA </b-th>
              <b-th class="newcolor">PU TTC </b-th>
              <b-th class="newcolor">Devise </b-th>
              <b-th class="newcolor">Description </b-th>
              <b-th class="newcolor">Longueur </b-th>
              <b-th class="newcolor">Largeur </b-th>
              <b-th class="newcolor">Hauteur </b-th>
              <b-th class="newcolor">Unité </b-th>
              <b-th class="newcolor">Poids </b-th>
              <b-th class="newcolor">Unité </b-th>
              <b-th class="newcolor">Nom catégorie </b-th>
              <b-th class="newcolor" colspan="2"></b-th>
            </b-tr>
            <b-tr v-for="produit in getProduits" v-bind:key="produit.id">
              <b-td>{{ produit.nom }}</b-td>
              <b-td>{{ produit.reference }}</b-td>
              <b-td>{{ produit.pu_ht }}</b-td>
              <b-td>{{ produit.tva ? produit.tva : '0' }}</b-td>
              <b-td>{{ produit.ttc }}</b-td>
              <b-td>{{ produit.devise ? produit.devise : '-' }}</b-td>
              <b-td>{{ produit.description ? produit.description : '-' }}</b-td>
              <b-td>{{ produit.longueur ? produit.longueur : '-' }}</b-td>
              <b-td>{{ produit.largeur ? produit.largeur : '-' }}</b-td>
              <b-td>{{ produit.hauteur ? produit.hauteur : '-' }}</b-td>
              <b-td>{{ produit.unite_l ? produit.unite_l : '-' }}</b-td>
              <b-td>{{ produit.poids ? produit.poids : '-' }}</b-td>
              <b-td>{{ produit.unite_p ? produit.unite_p : '-' }}</b-td>
              <b-td>{{
                produit.category && produit.category.nom_categorie
                  ? produit.category.nom_categorie
                  : '-'
              }}</b-td>
              <b-td>
                <b-button
                  class="button-danger-style ml-2"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="deleteProduit(produit)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-danger-style ml-2"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="updateProduit(produit)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </b-td>
            </b-tr>
            <b-tr v-if="getProduits.length == 0">
              <b-td colspan="15">
                Il n'y a aucun enregistrement à afficher</b-td
              >
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <div class="footer-style mt-2">
      <b-pagination
        v-model="page"
        :total-rows="getTotalRowProduit"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        align="center"
        size="sm"
        @change="pagination"
        class="pagination-style"
      ></b-pagination>

      <div class="per-page-element-style">
        <div class="box-label-champ">
          <div class="label-box-style">
            <span class="title-tabel">Eléments par page</span>
          </div>
        </div>
        <b-form-select
          v-model="perPage"
          :options="perPageList"
          @change="changePerPage"
          class="b-form-select-new-style bg-select"
        ></b-form-select>
      </div>
    </div>
    <b-modal
      id="updateProduit"
      ref="updateProduit"
      title="Modifier un produit"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap custom-modal-size"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier un produit</div>
        <div class="iconClose" @click.prevent="hideModal('updateProduit')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdateProduit"
            class="form-modal-custom-style"
          >
            <div class="ModelCol">
              <b-form-group
                label="Nom de produit"
                label-for="Nom de produit"
                class="input-modal-champ col-6 p-0"
              >
                <b-form-input
                  id="nom"
                  v-model="produit.nom"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Référence"
                label-for="Référence"
                class="input-modal-champ col-6 p-0"
              >
                <b-form-input
                  id="reference"
                  v-model="produit.reference"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="PU HT"
                label-for="PU HT"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-input
                  id="pu_ht"
                  v-model="produit.pu_ht"
                  required
                  @input="changePuHT"
                  pattern="[0-9-,-.]+"
                  title="This input should be only numbers"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="!isSelectInput"
                label="TVA"
                label-for="TVA"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-select
                  id="tva"
                  v-model="produit.tva"
                  required
                  :options="listTva"
                  @input="changePuHT(true)"
                  class="b-form-select-raduis "
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="TVA"
                label-for="TVA"
                class="input-modal-champ col-3 p-0"
                v-if="isSelectInput"
              >
                <b-form-input
                  id="tva"
                  v-model="produit.tva"
                  required
                  @input="changePuHT"
                  pattern="[0-9-,-.]+"
                  title="This input should be only numbers"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="PU TTC"
                label-for="PH TTC"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-input
                  id="ttc"
                  v-model="produit.ttc"
                  @input="changePuTTC"
                  pattern="[0-9-,-.]+"
                  title="This input should be only numbers"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Devise"
                label-for="Devise"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-select
                  id="devise"
                  v-model="produit.devise"
                  :options="listDevise"
                  class="b-form-select-raduis "
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Description"
                label-for="Description"
                class="input-modal-champ "
              >
                <b-form-textarea
                  id="description"
                  row="3"
                  v-model="produit.description"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Longueur"
                label-for="Longueur"
                class="input-modal-champ col-2 p-0 "
              >
                <b-form-input
                  id="longueur"
                  v-model="produit.longueur"
                  pattern="[0-9-,-.]+"
                  title="This input should be only numbers"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Largeur"
                label-for="Largeur"
                class="input-modal-champ col-2 p-0 "
              >
                <b-form-input
                  id="largeur"
                  v-model="produit.largeur"
                  pattern="[0-9-,-.]+"
                  title="This input should be only numbers"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Hauteur"
                label-for="Hauteur"
                class="input-modal-champ col-2 p-0 "
              >
                <b-form-input
                  id="hauteur"
                  v-model="produit.hauteur"
                  pattern="[0-9-,-.]+"
                  title="This input should be only numbers"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Unité"
                label-for="Unite_l"
                class="input-modal-champ col-2 p-0 "
              >
                <b-form-select
                  id="unite_l"
                  :options="unites_longeur"
                  v-model="produit.unite_l"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Poids"
                label-for="Poids"
                class="input-modal-champ col-2 p-0 "
              >
                <b-form-input
                  id="poids"
                  v-model="produit.poids"
                  pattern="[0-9-,-.]+"
                  title="This input should be only numbers"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Unité"
                label-for="Unite_p"
                class="input-modal-champ col-2  "
              >
                <b-form-select
                  id="unite_p"
                  :options="unites_poids"
                  v-model="produit.unite_p"
                  required
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Catégorie"
                label-for="Catégorie"
                class="input-modal-champ"
              >
                <b-form-select
                  id="categorie_id"
                  v-model="produit.categorie_id"
                  :options="getCategories"
                  text-field="nom_categorie"
                  value-field="id"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul
                    v-if="Array.isArray(error)"
                    class="mb-0"
                    style="list-style-type: none;"
                  >
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style ">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="deleteProduitModal"
      id="deleteProduitModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un produit</div>
        <div class="iconClose" @click.prevent="hideModal('deleteProduitModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleDeleteProduit"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cette produit?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Gestion-des-produits',
  data() {
    return {
      listTva: ['0', '2.1', '5.5', '7.7', '8.5', '10', '20', 'Autre'],
      unites_longeur: ['cm', 'm'],
      unites_poids: ['kg', 'g'],
      listDevise: [
        'USD',
        'EUR',
        'GBP',
        'CAD',
        'JYP',
        'CHF',
        'XPF',
        'XAF',
        'XOF',
        'PLN'
      ],
      produitToDelete: null,
      loading: false,
      error: [],
      isSelectInput: false,
      produit: {
        nom: null,
        reference: null,
        tva: 0,
        pu_ht: 0,
        devise: 'EUR',
        description: null,
        longueur: null,
        largeur: null,
        hauteur: null,
        unite_l: 'cm',
        unite_p: 'kg',
        categorie_id: null,
        ttc: 0,
        poids: null,
        categorie_id: null
      },
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'fetchAllProducts',
      'addNewProduct',
      'UpdateProduct',
      'deleteProduct',
      'fetchAllCategories'
    ]),
    async changePerPage() {
      this.page = 1;
      await this.fetchAllProducts({ per_page: this.perPage, page: this.page });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.fetchAllProducts({ per_page: this.perPage, page: this.page });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },

    async handleSubmitAddProduit() {
      this.loading = true;
      const response = await this.addNewProduct(this.produit);
      if (response.success) {
        this.loading = false;
        this.hideModal('addProduit');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },

    updateProduit(data) {
      if (this.listTva.indexOf(data.tva) === -1) {
        this.listTva.unshift(data.tva);
      }
      this.produit = { ...data };
      this.produitOrigin = data;
      this.$refs['updateProduit'].show();
    },
    async handleUpdateProduit() {
      this.loading = true;
      const response = await this.UpdateProduct({
        produit: this.produit,
        produitOrigin: this.produitOrigin
      });
      if (response.success) {
        this.loading = false;
        this.hideModal('updateProduit');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    deleteProduit(data) {
      this.produitToDelete = data;
      this.$refs['deleteProduitModal'].show();
    },
    async handleDeleteProduit() {
      this.loading = true;
      const response = await this.deleteProduct(this.produitToDelete);
      if (response.success) {
        this.loading = false;
        this.hideModal('deleteProduitModal');
        if (this.getProduits.length === 0 && this.page > 1) {
          let newPage = this.page - 1;
          this.pagination(newPage);
        }
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    resetModal() {
      this.listTva = ['0', '2.1', '5.5', '7.7', '8.5', '10', '20', 'Autre'];
      this.produit = {
        nom: null,
        reference: null,
        tva: 0,
        pu_ht: 0,
        devise: 'EUR',
        description: null,
        longueur: null,
        largeur: null,
        hauteur: null,
        unite_l: 'cm',
        unite_p: 'kg',
        categorie_id: null,
        ttc: 0,
        poids: null,
        categorie_id: null
      };
      this.isSelectInput = false;
      this.error = [];
      this.loading = false;
      this.produitToDelete = null;
    },

    changePuHT(e) {
      if (e && this.produit.tva === 'Autre' && this.isSelectInput === false) {
        this.produit.tva = 0;
        this.isSelectInput = true;
      }
      this.produit.ttc = (
        parseFloat((this.produit.pu_ht + '').replace(',', '.')) +
        (parseFloat((this.produit.pu_ht + '').replace(',', '.')) / 100) *
          parseFloat((this.produit.tva + '').replace(',', '.'))
      ).toFixed(2);
    },
    changePuTTC() {
      this.produit.pu_ht = (
        (parseFloat((this.produit.ttc + '').replace(',', '.')) /
          (100 + parseFloat((this.produit.tva + '').replace(',', '.')))) *
        100
      ).toFixed(2);
    }
  },
  computed: {
    ...mapGetters([
      'getProduits',
      'getProduitError',
      'getTotalRowProduit',
      'getCategories',
      'getProduitIsLoding'
    ])
  },
  components: {
    Card: () => import('../component/card.vue')
  },
  async mounted() {
    await this.fetchAllProducts({ per_page: this.perPage, page: this.page });
    await this.fetchAllCategories();
  }
};
</script>

<style scoped lang="scss">
.contentProduct {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .body-box-setting {
    height: calc(100vh - 194px) !important;
  }
  .chargement {
    font-size: 8px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
.tableFixHead {
  position: sticky;
  top: -10px;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 170px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
</style>
